import CAS from './cas.js'
import SDS from './sds.js'
import Login from './login.js'
import {useState} from 'react'
export default function App() {

  const PORT_BACK = 8000
  const SERVER_URL = "https://inscripcion.ar:"

  const back = SERVER_URL + PORT_BACK + "/"

  const [token, setToken] = useState('')
  const [eventos, setEventos] = useState([])
  const [evento,setEvento] = useState(7)
  const [evento_nombre, setEvento_nombre] = useState('')
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(0)
  const [quien, setQuien] = useState({rol:0})
  const [id, setID] = useState(0)

  function login() {
    const values = {
      usuario: usuario,
      password: password
    }
    const data ={
      body: JSON.stringify(values),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
    fetch(back + 'login', data)
    .then (response => response.json())
    .then (a => {
      if (a.rol=== undefined) {
          setToken(a)
          var d = JSON.parse(window.atob(a.split('.')[1]))
          setQuien(d)
          traer_eventos(a,d.evento_id)
          setError(0)
          setID(d.id)
        }
        else {
          setQuien({rol:0})
          setError (1)
        }
      setUsuario('')
      setPassword('')
    })
    .catch(function (error) {
      console.log("Hubo un problema con la petición Fetch:" + error.message)
      setError(2)
    })
  }

function traer_eventos (token, evento_id) {
  const data ={
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json' 
    }
  }
  fetch(back + 'admin/traer_eventos', data)
  .then (response => response.json())
  .then (json => {
      setEventos(json)
      const cual = json.filter (a => a.id === evento_id)
      if (cual.length) {
        setEvento(cual[0].id)
        setEvento_nombre(cual[0].descripcion)
      } else { 
       setEvento(json[0].id)
       setEvento_nombre(json[0].descripcion)
      }
  })
}

return (<>
    <div className="flex flex-col w-screen h-screen bg-orange-200 overflow-hidden">
    {quien.rol===0 ?
        <Login props usuario={usuario} setUsuario={setUsuario} password={password} setPassword={setPassword} error={error} login={login}/>  
    : '' }
    {quien.rol!==0 ?
    <div className="flex flex-col w-screen">
      <div className="flex flex-row justify-between p-2 w-full bg-gray-800 text-white pr-5">
       <div className="justify-between text-2xl">
         <label className="font-semibold pr-5">Evento:</label>
         {quien.rol===2 ?
         <select className="outline-0 text-lg text-black" value={evento} onChange={e => setEvento(parseInt(e.target.value))}>
          {eventos.map((a) => {
            return <option key={a.id} value={a.id}>{a.nombre}</option>
          })
         }
         </select>
         : 
         <label>{evento_nombre}</label>
        }
       </div>
       <div className="justify-between text-2xl">
         <label className="font-semibold pr-5">Usuario:</label><label>{' ' + quien.apellido + ' ' + quien.nombre}</label>
       </div>
     </div>
     </div>
     : ''
     }
        {
      quien.rol>=1 && evento===11 ? 
       
        <SDS id={id} quien={quien} evento={evento} token={token} />
      :
      ''
      }
      {
      quien.rol>=1 && evento===12 ? 
       
        <CAS id={id} quien={quien} evento={evento} token={token} />
      :
      ''
      }
    </div>  
  </>)
}